import './css/AcceptInvite.scss'
import { useHistory } from 'react-router-dom'
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Customer, CustomerUser, fetchPost, useModel } from '@oshcut/oshlib'
import { PrimaryButton, SecondaryButton } from '@oshcut/components'
import md5 from 'md5'
import Card from './Card'

const AcceptInvite = () => {

  const history = useHistory()

  const params = new URLSearchParams(document.location.search)
  let token = params.get('t')

  const [customer, setCustomer] = useState<Customer | undefined>() 
  const [customerUser, setCustomerUser] = useState<CustomerUser | undefined>()
  const [submitted, setSubmitted] = useState(false)


  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [validateError, setValidateError] = useState("")

  const validateInvite = useCallback(async () => {

    //Redirect back to cart if no jwt
    if(!token) {
      history.push('/cart')
      return
    }

    try {
      const response = await fetchPost('/api/v3/customer_user/validate_invite', { token })

      const { customer, customer_user } = response

      setCustomer(customer)
      setCustomerUser(customer_user)
      setFirstName(customer_user.first_name || '')
      setLastName(customer_user.last_name || '')

    } catch(e: any) {
      console.error(e)
      if(e.serverMessage) {
        setValidateError(e.serverMessage)
        return
      }
      if(e.message) {
        setValidateError(e.message)
        return
      }
      setValidateError("Unknown error occurred")
    }
  }, [token])

  useEffect(() => {
    validateInvite()
  }, [token])

  const [submitError, setSubmitError] = useState("")

  async function handleAccept(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    setSubmitError("")
    if(password !== confirmPassword) {
      setSubmitError("Passwords do not match")
      return
    }

    try {
      const result = await fetchPost('/api/v3/customer_user/accept_invite', {
        token,
        first_name: firstName,
        last_name: lastName,
        password: md5(password)
      })

      setSubmitted(true)
    } catch(e: any) {
      setSubmitError(e.serverMessage)
    }
    
  }

  async function handleDecline() {
    try {
      const result = await fetchPost('/api/v3/customer_user/decline_invite', {
        token,
      })

      history.push('/cart')
    } catch(e: any) {
      setSubmitError(e.serverMessage)
    }
  }

  /**
   * For names such as "Company Inc.", it looks a bit awkward
   * to end the sentence with an extra period. 
   */
  const companyNameWithPeriod = useMemo(() => {
    if(customer && customer.company) {
      return customer.company.endsWith('.') ? customer.company : `${customer.company}.`
    }
    return undefined
  }, [customer]) 


  return (
    <Card className='AcceptInvite' title='Accept Company Invite'>

      {submitted ? (<>

        <p>Success! You have accepted your invitation{companyNameWithPeriod ? <b> to {companyNameWithPeriod}</b> : '.'}</p>
        <p>Check your email for instructions how to sign in to your new account.</p>

      </>) : (

        (customerUser && customer) ? (<>
          <p>You have been invited to join {companyNameWithPeriod ? <b>{companyNameWithPeriod}</b> : 'a company.'} To accept this invite, please verify your name and choose a password.</p>
          <p>If you accept this invite, you'll be able to view and submit orders on behalf of {companyNameWithPeriod ? <b>{companyNameWithPeriod}</b> : 'the company.'} If you have an existing account, it will not be affected.</p>

          <form className='form acceptInviteForm' onSubmit={handleAccept}>

            <label>
              <span>Email</span>
              <b>{customerUser.email}</b>
            </label>

            <label>
              <span>First Name</span>
              <input autoComplete="given-name" type="text" placeholder='First Name' value={firstName} onChange={e => setFirstName(e.target.value)} />
            </label>

            <label>
              <span>Last Name</span>
              <input autoComplete="family-name" type="text" placeholder='Last Name' value={lastName} onChange={e => setLastName(e.target.value)} />
            </label>

            <label>
              <span>Password</span>
              <input autoComplete='new-password' type="password" placeholder='Choose a Password' value={password} onChange={e => setPassword(e.target.value)} />
            </label>

            <label>
              <span>Confirm Password</span>
              <input autoComplete='new-password' type="password" placeholder='Re-Enter Password' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
            </label>

            {submitError && <p className='error'>{submitError}</p>}

            <PrimaryButton type="submit" disabled={!firstName || !lastName || !password || !confirmPassword}>Accept Invite</PrimaryButton>
          </form>
          <SecondaryButton className="declineInvite" onClick={handleDecline}>Decline</SecondaryButton>
        </>) : validateError ? (
          <p>{validateError}</p>
        ) : <p>Loading...</p>

      )}


    </Card>
  )

}

export default AcceptInvite