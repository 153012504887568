import { LinkButton, OshTable, OshTableColumn, OshTableFormat, OshTableHeader, OshTableRow, PrimaryButton } from '@oshcut/components';
import { LibraryPart, OrderForCustomer, OrderPart, PipeStatus } from '@oshcut/oshlib';
import { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { CartPart, ParsedOrder } from 'types';
import { ItemToAdd } from 'util';
import './css/OrderPartsTable.scss';
import { OrderPartsTableRow } from './OrderPartsTableRow';

type OrderDetailsProps = {
  order: ParsedOrder<OrderForCustomer>
  cartPartsWithPipeStatus: (CartPart & { pipeStatus: PipeStatus[] | undefined })[]
  onAddToCart: (itemsToAdd: ItemToAdd[]) => void
  onSavedCardTitleUpdate: (newTitle: string) => void
  onToggleStar: (starred: boolean) => void
  onAddAllToCart: () => void
  onAddToPartLibrary: (libraryPart: LibraryPart, orderPart: OrderPart) => void
}

/**
 * Displays a full-width table of parts of an order and allows the user to add them to the current order
 * For saved carts, allows the user to star it and edit the title
 * @param param0 
 * @returns 
 */
const OrderPartsTable = ({ order, cartPartsWithPipeStatus, onAddToCart, onSavedCardTitleUpdate, onToggleStar, onAddAllToCart,onAddToPartLibrary }: OrderDetailsProps) => {

  const subtotal = useMemo(() => {
    return cartPartsWithPipeStatus.reduce((acc, cp) => acc + cp.total, 0)
  }, [cartPartsWithPipeStatus])

  const isSavedCart = !!(order.status === 'saved')

  const isInProduction = !["draft", "saved", "cancelled", "quoted", "shipped"].includes(order.status)

  const [cartTitle, setCartTitle] = useState(order.description || 'Untitled Cart')
  const cartTitleInputRef = useRef<HTMLInputElement>(null)

  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  useEffect(() => {
    if (isEditingTitle && cartTitleInputRef.current) {
      cartTitleInputRef.current.select()
    }
  }, [isEditingTitle, cartTitleInputRef])

  function saveTitleEdit() {
    if (!cartTitle.trim()) return
    setIsEditingTitle(false)
    onSavedCardTitleUpdate(cartTitle)
  }

  function cancelTitleEdit() {
    setIsEditingTitle(false)
    setCartTitle(order.description || 'Untitled Cart')
  }

  function handleTitleInputKeydown(e: KeyboardEvent<HTMLInputElement>) {
    if (!cartTitle) return
    if (e.key === 'Enter') {
      onSavedCardTitleUpdate(cartTitle)
    }
  }

  const cartIsStarred = !!order.customer_starred

  return (
    <div className="OrderPartsTable">

      <div className="header">
        {/* Cart Title (only applicable for 'saved' orders) */}
        {isSavedCart ? (<div className="cartTitle">

          <FaStar className={`star ${cartIsStarred ? 'active' : ''}`} onClick={_ => onToggleStar(!cartIsStarred)} />

          {!isEditingTitle ?
            <div className='title'>
              <div className={'cartTitle'}>{cartTitle}</div>
              <LinkButton onClick={_ => setIsEditingTitle(true)}>Edit</LinkButton>
            </div> :
            <div className="title editing">
              <input
                ref={cartTitleInputRef}
                value={cartTitle}
                onChange={(e) => { setCartTitle(e.target.value) }}
                onKeyDown={handleTitleInputKeydown}
              />
              <LinkButton onClick={saveTitleEdit}>Save</LinkButton>
              <LinkButton onClick={cancelTitleEdit}>Cancel</LinkButton>
            </div>
          }

        </div>) : null}

        {/* Add to Cart Button */}
        <PrimaryButton className='addAllToCartButton' onClick={onAddAllToCart}>Add All To Cart</PrimaryButton>
      </div>

      <OshTable>
        <OshTableHeader>
          <OshTableFormat label={''} width={12} />
          <OshTableFormat label={'Part'} width={20} />
          <OshTableFormat label={'Status'} width={24} />
          <OshTableFormat label={'Price'} width={12} />
          <OshTableFormat label={'Qty'} width={6} />
          <OshTableFormat label={'Total'} width={12} />
          <OshTableFormat label={''} width={14} />
        </OshTableHeader>

        {cartPartsWithPipeStatus.map((part, index) => {

          return <OrderPartsTableRow
            key={index}
            part={part}
            isInProduction={isInProduction}
            onAddToCart={onAddToCart}
            onAddToPartLibrary={onAddToPartLibrary}
          />

        })}

        <OshTableRow className={'costing subtotalRow'}>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn>Subtotal</OshTableColumn>
          <OshTableColumn>${subtotal.toFixed(2)}</OshTableColumn>
          <OshTableColumn></OshTableColumn>
        </OshTableRow>

        {!isSavedCart ? <OshTableRow className={'costing taxRow'}>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn>Tax</OshTableColumn>
          <OshTableColumn>${order.tax.toFixed(2)}</OshTableColumn>
          <OshTableColumn></OshTableColumn>
        </OshTableRow> : null}

        {!isSavedCart ? <OshTableRow className={'costing shippingRow'}>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn>Shipping</OshTableColumn>
          <OshTableColumn>${order.shipping_price.toFixed(2)}</OshTableColumn>
          <OshTableColumn></OshTableColumn>
        </OshTableRow> : null}

        {!isSavedCart ? <OshTableRow className={'costing totalRow'}>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn></OshTableColumn>
          <OshTableColumn className='border'>Order Total</OshTableColumn>
          <OshTableColumn className='border'>${order.total.toFixed(2)}</OshTableColumn>
          <OshTableColumn></OshTableColumn>
        </OshTableRow> : null}

      </OshTable>
    </div>
  )
}

export default OrderPartsTable
