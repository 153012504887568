import { BlockPlaceholder, LinkButton, LoadingSpinner } from '@oshcut/components'
import { LibraryPart, OrderForCustomer, OrderPart, PipeStatus, PipeStatusForOrdersApi, SalesInvoice, Shipment, fetchPost } from '@oshcut/oshlib'
import { useLoadingOverlay } from 'hooks/useLoadingOverlay'
import { DateTime } from 'luxon'
import { getPercentCompletionMulti } from 'pipeStatus'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { DispatchFunction } from 'reducer'
import { CartPart, ParsedOrder, StateType } from 'types'
import { formatCityStateZip, getTrackingURL, ItemToAdd, loadCartPartsForOrder } from '../util'
import { useAddPartsToCurrentOrder } from 'hooks/useAddPartsToCurrentOrder'
import Card from './Card'
import { getErrorText } from './InvoicesView/OpenInvoices'
import OrderPartQtyBoxes from './OrderPartQtyBoxes'
import OrderPartsTable from './OrderPartsTable'
import OrderStatusTag from './OrderStatusTag'
import './css/OrderDetails.scss'
import { useClientWidth } from 'hooks/useClientWidth'
import OrderPartsTableMobile from './OrderPartsTableMobile'

type CertDetail = {
    item_id: number,
    material_sheet_id: number,
    material_tube_id: number,
    description: string,
    file_ids: string[]
}

type OrderDetailsSlideoverProps = {
  state: StateType
  dispatch: DispatchFunction
  order: ParsedOrder<OrderForCustomer>
  onOrderUpdate?: (order: ParsedOrder<OrderForCustomer>) => void
}

const OrderDetails = ({ state, dispatch, order, onOrderUpdate }: OrderDetailsSlideoverProps) => {

  const clientWidth = useClientWidth()

  const [errors, setErrors] = useState<string[]>([])

  const [cartParts, setCartParts] = useState<CartPart[] | null>(null)

  const { addPartsToCurrentOrder, overlayContent: addPartsOverlayContent } = useAddPartsToCurrentOrder({ state, dispatch, onError: e => setErrors(cv => [...cv, e]) })

  // Load the cart parts for the order
  useEffect(() => {
    loadCartPartsForOrder(
      order,
      state.materialTypes,
      state.materialSheets,
      state.materialTubes,
      state.powders
    ).then((cp) => {
      setCartParts(cp)
    }).catch((e) => {
      setErrors(cv => [...cv, e.message])
    })

  }, [order])


  const [pipeStatuses, setPipeStatuses] = useState<PipeStatusForOrdersApi.PipeStatusForOrderPart[]>()
  const [certs, setCerts] = useState<CertDetail[]>()

  const fetchPipeStatuses = useCallback(async () => {
    try {
      const ps = await fetchPost(
        "/api/v2/pipe_status/for_order_parts_of_order",
        { order_id: order.id }
      )
      setPipeStatuses(ps)
    } catch(_) {
      setErrors(cv => [...cv, "Failed to fetch progress for this order."])
    }

  }, [setPipeStatuses, setErrors, order])

  const fetchCertsInfo = useCallback(async () => {
    try {
      const result = await fetchPost("/api/v2/order/certs", {guid: order.guid}) as {certs: CertDetail[]}
      setCerts(result.certs)
    } catch (_) {
      setErrors(cv => [...cv, "Failed to fetch certs for this order."])
    }
  }, [order])

  // Fetch pipe status and certs on load
  useEffect(() => {
    fetchPipeStatuses()
    fetchCertsInfo()
  }, [fetchPipeStatuses, fetchCertsInfo])

  /** Order Parts with pipe status rolled up */
  const cartPartsWithPipeStatus = useMemo(() => {
    if(!cartParts || !pipeStatuses) return
    return cartParts.map(op => {
      const myPipeStatus = pipeStatuses.find(ps => (ps.order_part_id === op.orderPart.id))?.pipe_status
      return {
        ...op,
        pipeStatus: myPipeStatus
      } as CartPart & { pipeStatus: PipeStatus[] | undefined }
    })
  }, [cartParts, pipeStatuses])

  const [salesInvoices, setSalesInvoices] = useState<SalesInvoice[] | null>(null)


  useEffect(() => {
    if(!order.use_invoices) return

    fetchPost('/api/v2/sales_invoice/get_for_order', { order_id: order.id })
      .then(result => setSalesInvoices(result.salesInvoices))
      .catch(e => setErrors(cv => [...cv, "Failed to get invoices for this order."]))
  }, [order.id])


  const [overlayContent, showLoadingOverlay, hideLoadingOverlay] = useLoadingOverlay();

  async function handleAddToCart(itemsToAdd: ItemToAdd[]) {
    addPartsToCurrentOrder(itemsToAdd)
  }

  async function handleAddAllToCart() {
    if (!cartParts) return
    let itemsToAdd: ItemToAdd[] = cartParts
      .filter(part => part.id.indexOf('P-') === 0)
      .map(cp => ({
        orderPart: cp.orderPart,
        libraryPart: cp.libraryPart,
        qty: cp.orderPart.qty
      }))
    handleAddToCart(itemsToAdd)
  }

  const isSavedCart = order.status === 'saved'

  async function handleTitleSave(newTitle: string) {
    showLoadingOverlay('Saving your order')
    try {
      let updatedOrder = await fetchPost('/api/v2/order/update', {
        guid: order.guid,
        description: newTitle
      })
      onOrderUpdate?.(updatedOrder.order)
    } catch (ex) {
      console.error(ex)
      setErrors(cv => [...cv, 'We couldn\'t save changes to your cart. Please contact support for assistance.'])
    }
    hideLoadingOverlay()
  }
  async function handleToggleStar(starred: boolean) {
    try {
      let updatedOrder = await fetchPost('/api/v2/order/update', {
        guid: order.guid,
        customer_starred: starred
      })
      onOrderUpdate?.(updatedOrder.order)
    } catch (e) {
      console.error(e)
      setErrors(cv => [...cv, 'We couldn\'t save changes to your cart. Please contact support for assistance.'])
    }
  }

  function onAddToPartLibrary(libraryPart: LibraryPart, orderPart: OrderPart) {
    // Update local state with the new library part and order part
    setCartParts(prev => {
      if (!prev) return prev
      return prev.map(cp => {
        if (cp.orderPart.id === orderPart.id) {
          return {
            ...cp,
            orderPart,
            libraryPart,
            isLibraryPart: true,
            name: orderPart.name
          }
        }
        return cp
      })
    })
  }

  const paymentMethodString = useMemo(() => {
    switch (order.terms) {
      case 'CC':
        return `Card ending in ${order.card_ending}`
      default: return ''
    }
  }, [order])

  const [shipments, setShipments] = useState<(Shipment & { friendly_method: string })[]>()
  const [shipmentsAreLoading, setShipmentsAreLoading] = useState(false)
  const [shipmentFetchError, setShipmentFetchError] = useState<string>()

  function tryFetchShipments() {
    setShipmentsAreLoading(true)
    setShipmentFetchError(undefined)
    setShipments(undefined)
    fetchPost("/api/v2/shipment/get_for_order", { order_id: order.id })
      .then(apiShipments => {
        const filteredShipments = apiShipments.filter(s => (s.status !== "draft") && (s.carrier !== "Pickup"))
        setShipments(filteredShipments)
      })
      .catch(err => {
        console.error(err)
        setShipmentFetchError(getErrorText(err))
      })
      .finally(() => setShipmentsAreLoading(false))
  }

  useEffect(() => {
    tryFetchShipments()
  }, [order])


  return (
    <div className='OrderDetails'>

      {errors.length > 0 && <div className="errors">
        {errors.map((e, i) => <div key={i} className='error'><FaExclamationTriangle /><span>{e}</span></div>)}
      </div>}



      {!isSavedCart && <div className="details">

        {/* Main Details Section */}
        <Card className="mainDetails" title={<><h1>{order.internal_id || 'Untitled Order'}</h1><OrderStatusTag status={order.status} fontSize={18} /></>}>
          
          <div className="dates">
            <p>Placed on {DateTime.fromMillis(order.order_date).toFormat('DDD')}</p>
            <p>Due on or before {DateTime.fromMillis(order.target_ship_date).toFormat('DDD')}</p>
          </div>

          <div className="payment">
            <p><b>Payment Method</b></p>
            <p>{paymentMethodString}</p>
          </div>

          <div className="shipTo">
            {order.is_pickup === 1 ? (<>

              <div className='orderDetailsLabel'>Pickup Contact</div>
              <div>{order.ship_name}</div>
              <div>Phone: {order.phone}</div>

            </>) : (<>

              <div className='orderDetailsLabel'>Ship To</div>
              <div>{order.ship_company}</div>
              <div>{order.ship_name}</div>
              <div>{order.bill_phone}</div>
              <div>{order.ship_address}</div>
              <div>{order.ship_address2}</div>
              <div>{order.ship_address3}</div>
              <div>{formatCityStateZip(order.ship_city, order.ship_state, order.ship_zip)}</div>
              <div>{order.ship_country}</div>

            </>)}
          </div>

          <div className="billTo">
            <div className="orderDetailsLabel">Bill To</div>
            <div>{order.bill_company}</div>
            <div>{order.bill_name}</div>
            <div>{order.bill_phone}</div>
            <div>{order.bill_address}</div>
            <div>{order.bill_address2}</div>
            <div>{order.bill_address3}</div>
            <div>{formatCityStateZip(order.bill_city, order.bill_state, order.bill_zip)}</div>
            <div>{order.bill_country}</div>
          </div>

          <div className="ack">
            <a href={`/api/v2/order/acknowledgment/pdf?id=${order.id}`} target='_blank'>Order Acknowledgment</a>
          </div>

        </Card>

        {/* Shipments, invoices and production status */}
        <Card className="shipmentsInvoicesProduction">

          {/* Shipments */}
          <div className="shipmentsContainer">
            <div className="orderDetailsLabel">Shipments</div>
            <div className="shipments">
              {shipmentsAreLoading ? (<>
                <BlockPlaceholder style={{height: 16, borderRadius: 3, marginBottom: 1 }} />
                <BlockPlaceholder style={{height: 30, borderRadius: 3, marginBottom: 1 }} />
              </>) : (<>

                {/* Shipments loaded. If more than zero, display each one. */}
                {shipments ? (
                  shipments.length > 0 ? (<>
                    <div className="shipment header">
                      <span>Method</span>
                      <span>Tracking No.</span>
                      <span>Packing List</span>
                    </div>
                    {shipments.map(shipment => {
                      const trackingURL = getTrackingURL(shipment.carrier, shipment.tracking)
                      return <div className="shipment" key={shipment.id}>
                        <span>{shipment.carrier} {shipment.friendly_method}</span>
                        <span>{trackingURL ? <a href={trackingURL ?? ""} target="_blank" rel="noopener noreferrer">{shipment.tracking}</a> : shipment.tracking}</span>
                        {/* <span>{shipment.tracking}</span> */}
                        <span><a href={`/api/v2/shipment/pack_list?shipment_id=${shipment.id}`} target="_blank">Packing List</a></span>
                      </div>
                    })}
                  </>) : (
                    <p>None found.</p>
                  )
                ) : null}

                {/* Fetch call failed with some error. */}
                {shipmentFetchError ? (<>
                  <p className="error">Failed to fetch shipments.</p>
                  <LinkButton onClick={tryFetchShipments}>Try again</LinkButton>
                </>) : null}

              </>)}
            </div>
          </div>

          {/* Invoices */}
          <div className="invoicesContainer">
            <div className="orderDetailsLabel">Invoices</div>
            <div className="invoices">

              {!!order.use_invoices && (

                salesInvoices != null ? (<>

                  {salesInvoices.length > 0 && salesInvoices.filter(si => si.status !== 'voided' && si.status !== 'draft').map(si => {
                    const dtNow = DateTime.fromMillis(Date.now())
                    const dtDue = si.due_date != null ? DateTime.fromMillis(si.due_date): null
                    const dayDifference = dtDue != null ? Math.ceil(dtDue.diff(dtNow, 'days').days) : null
                    const daysPastDue = (dayDifference != null && dayDifference < 0) ? Math.abs(dayDifference) : null
                    const isPastDue = si.status === 'approved' && si.outstanding_balance_cents > 0 && daysPastDue && daysPastDue > 0
                    
                    return (<div className="invoice" key={si.id}>
                      <div><a href={`/api/v2/sales_invoice/pdf?id=${si.id}`} target="_blank">{si.invoice_number}</a></div>
                      <div className={isPastDue ? 'pastDue' : ''}>{si.outstanding_balance_cents === 0 ? '(Paid)' : `($${(si.outstanding_balance_cents / 100).toFixed(2)} due by ${DateTime.fromMillis(si.due_date).toFormat('DDD')})`}</div>
                    </div>)
                  })}

                  {salesInvoices.length == 0 && <>Not yet invoiced.</>}

                </>) : (
                  <LoadingSpinner />
                ) 
              )}
              
              {!order.use_invoices && (
                order.status === 'shipped' ? <a href={`/api/v1/order/invoice?id=${order.id}`} target="_blank">{order.internal_id || 'Untitled Order'}</a> : 'Not yet invoiced.'
              )} 

            </div>
          </div>

          {/* Production */}
          {!["draft", "saved", "cancelled", "quoted", "shipped"].includes(order.status) ? (
            <div className="productionContainer">
              <div className="orderDetailsLabel">Production Status</div>
              {(pipeStatuses) ? (
                <OrderPartQtyBoxes data={getPercentCompletionMulti(pipeStatuses)} />
              ) : <BlockPlaceholder style={{height: 45, width: 200, borderRadius: 5}} />}
            </div>
          ) : <></>}

          {/* Certs */}
          {certs && certs.length > 0 ? (
            <div className="certsContainer">
              <div className="orderDetailsLabel">Mill Certs</div>
              <div className="certs">
                {certs.map(cert => (
                  <div className="cert" key={cert.item_id}>
                    {cert.file_ids.length === 1 && <div><a href={`/api/v2/attachment/download?file_id=${cert.file_ids[0]}`} target="_blank">{cert.description}</a></div>}
                    {cert.file_ids.length > 1 && (<>
                      <div>{cert.description} (multiple certs)</div>
                      {cert.file_ids.map((file_id, i) => (<div className="certHeatOption" key={i}><a href={`/api/v2/attachment/download?file_id=${file_id}`} target="_blank">Download #{i + 1}</a></div>))}
                    </>)}
                  </div>
                ))}
              </div>
            </div>
          ) : <></>}

        </Card>

      </div>}

      {/* Parts table */}
      {cartPartsWithPipeStatus ? (<Card className="parts">

        {clientWidth >= 1000 && (
          <OrderPartsTable
            order={order}
            cartPartsWithPipeStatus={cartPartsWithPipeStatus}
            onAddToCart={handleAddToCart}
            onSavedCardTitleUpdate={handleTitleSave}
            onToggleStar={handleToggleStar}
            onAddAllToCart={handleAddAllToCart}
            onAddToPartLibrary={onAddToPartLibrary}
          />
        )}

        {clientWidth < 1000 && (
          <OrderPartsTableMobile
            order={order}
            cartPartsWithPipeStatus={cartPartsWithPipeStatus}
            onAddToCart={handleAddToCart}
            onSavedCardTitleUpdate={handleTitleSave}
            onToggleStar={handleToggleStar}
            onAddAllToCart={handleAddAllToCart}
            onAddToPartLibrary={onAddToPartLibrary}
          />
        )}

      </Card>) : <BlockPlaceholder style={{height: 400, borderRadius: 5}} />}


      {/* FAQs section (only for in-progress orders) */}
      {!['draft', 'saved', 'cancelled', 'shipped'].includes(order.status) && (
        <div className="faqs">
          <div className="faq">
            <b>When Will My Order Be Ready?</b>
            <p>
              Your order should ship by its due date, but we often ship orders early.  We'll let you know by email when your order ships.
            </p>
          </div>
          <div className="faq">
            <b>What If I Want to Make a Change?</b>
            <p>
              You can generally request changes to your order as long as it hasn't been released to production. We may start your order early, so please let us know of any required changes as quickly as you can.
            </p>
            <p>
              You can contact us by replying to your order confirmation email, or you can email us at <a href="mailto:support@oshcut.com">support@oshcut.com</a>. You can also call our office at 801-850-7584.
            </p>
          </div>
          <div className="faq">
            <b>When Will I Be Charged?</b>
            <p>Your order will be charged just before it ships. If your card declines, your order will be held until the payment issue is corrected.</p>
          </div>
        </div>
      )}

      {overlayContent}
      {addPartsOverlayContent}
    </div>
  )
}

export default OrderDetails