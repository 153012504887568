import { ArcifyRequest, fetchPostStream } from '@oshcut/oshlib'

/**
 * Start a streaming http transaction with the arcify server, and return a Promise that will be fulfilled when the transaction is complete.
 * @param {Object} payload Data to send to the arcify server.
 * @param {Function} onUpdate Callback function that will be called if an update is received during the transaction.
 * @returns {Promise} A Promise that will resolve with the result of the arcify operation.
 */
export async function arcifyHttpTransaction(payload: ArcifyRequest, onUpdate = (msg: any) => { }) {

  payload.updates = payload.updates ?? true

  let response = await fetchPostStream('/api/v2/arcify', payload, onUpdate)
  return response.result

}